<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-text v-if="hasSEAPermissions">
          Use this form to add new negative keywords or bookings.<br />
          The new entries will be synced to the appropriate Google and Bing accounts every hour from 06:45 CET until 22:45 CET each weekday.
        </v-card-text>
        <v-card-text v-else>
          Use this form to add new negative keywords or bookings. The new entries will need to be approved by a SEA member after you have saved them.<br />
          After approval, the new entries will be synced to the appropriate Google and Bing accounts every hour from 06:45 CET until 22:45 CET each weekday.<br /><br />

          <h4>Match type: Exact or Phrase</h4>
          <p>
            <ul>
              <li><strong>Exact:</strong> search terms that match your keyword word by word (<i>same word order + same components</i>)</li>
              <li><strong>Phrase:</strong> search terms that contain all components of your keyword <i>in the same order</i></li>
            </ul>
          </p>
          <p>The goal when adding negatives is to meet the client’s requirements, but always trying to minimize the impact on our Paid Search business. 
            So please keep in mind that the <strong>Exact match type has a lower impact than the Phrase type</strong>, therefore:
            <ul>
              <li>Choose <strong>Exact</strong> when the partner first sends the list of keywords, unless he specifically asks for Phrase</li>
              <li>Choose <strong>Phrase</strong> if the partner complains about us still bidding on his brand</li>
            </ul>
          </p>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>
          Add negative keywords
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>

              <v-col md="6">
                <v-autocomplete v-model="selectedKeywordType" :disabled="!hasSEAPermissions" :items="keywordTypes" item-text="name" item-value="name"  label="Type" 
                  prepend-icon="remove_circle_outline" required :rules="[v => !!v || 'NKW type is required']" @change="keywordTypeChanged()">
                  <template v-slot:item="data">
                    <v-list-item-icon>
                      <v-icon>{{data.item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-autocomplete v-model="selectedMatchType" :items="matchTypes" label="Match type" prepend-icon="content_copy"
                  required :rules="[v => !!v || 'Match type is required']">
                </v-autocomplete>

                <v-autocomplete v-model="selectedVerticals" :items="activeVerticals" :loading="loadingVerticals" item-text="name" item-value="id" multiple label="Verticals" 
                  clearable prepend-icon="construction" required :rules="[v => !!(v && v.length) || '1 or more verticals are required']">
                  <template v-slot:append-outer>
                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-dots-vertical</v-icon>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item>
                            <v-list-item-title @click="refreshVerticals()">Refresh verticals</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                    <v-tooltip>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-1" v-on="on">help</v-icon>
                      </template>
                      <span>Select "Refresh verticals" from the menu on the left in order to update the list with the latest verticals from CMS
                      </span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>

                <v-autocomplete v-model="selectedProviders" :disabled="!providersEnabled" :loading="loadingProviders" :items="activeProviders" item-text="name" item-value="id" multiple label="Providers" 
                  clearable prepend-icon="business" :rules="[v => !!(v && v.length) || '1 or more providers are required']">
                  <template v-slot:append-outer>
                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-dots-vertical</v-icon>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item>
                            <v-list-item-title @click="refreshProviders()">Refresh providers</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                    <v-tooltip>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-1" v-on="on">help</v-icon>
                      </template>
                      <span>Select "Refresh providers" from the menu on the left in order to update the list with the latest providers from CMS
                      </span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>

                <v-autocomplete v-model="selectedRegions" :items="regions" item-text="name" item-value="id" multiple label="Regions" 
                  clearable prepend-icon="dashboard">
                  <template v-slot:append-outer>
                    <v-tooltip>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-1" v-on="on">help</v-icon>
                      </template>
                      <span>If new regions are needed, you can add them in the "Geos" section
                      </span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>

                <v-autocomplete v-model="selectedCountries" :items="activeCountries" item-text="name" item-value="id" multiple label="Countries" 
                  clearable prepend-icon="public">
                  <template v-slot:append-outer>
                    <v-tooltip>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-1" v-on="on">help</v-icon>
                      </template>
                      <span>If a country is not shown in the dropdown list, it needs to be set as Active in the "Geos" section
                      </span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col md="6">
                <v-textarea v-model="rawKeywords" label="Keywords" :hint="keywordsHint" :placeholder="keywordsPlaceholder" clearable dense rows="14"
                  required :rules="[v => !!v || 'Keywords are required. '+ keywordsHint]">
                </v-textarea>
              </v-col>

            </v-row>

            <v-card-actions>
              <v-btn color="primary" :loading="validating" @click="validate(false)">Validate</v-btn>
              <v-btn class="ml-5" color="error" :loading="saving" @click="validate(true)">Save</v-btn>
              <v-btn class="ml-5" @click="reset()">Reset</v-btn>

              <v-fade-transition mode="out-in">
                <v-btn color="green darken-1" text v-show="showValid">Valid</v-btn>
              </v-fade-transition>
              <v-fade-transition mode="out-in">
                <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
              </v-fade-transition>
            </v-card-actions>
          </v-form>
        </v-card-text>
        
      </v-card>

      <!-- when using white-space: pre, put code on single line to avoid unwanted spaces -->
      <v-alert v-model="showErrorAlert" color="error" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre;">{{errorMsg}}</v-alert>
      <v-alert v-model="showWarningAlert" color="warning" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="warning" style="white-space: pre;">{{warningMsg}}</v-alert>
      <v-alert v-model="showSuccessAlert" color="success" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="check" style="white-space: pre;">{{successMsg}}</v-alert>

    </v-col>
  </v-row>
</template>

<script>
import auth from '@/auth'
import formsMixin from '@/mixins/forms'

export default {
  name: 'AddNKWs',

  data () {
    return {
      selectedKeywordType: 'Brand',
      selectedMatchType: 'Exact',
      selectedVerticals: [],
      selectedProviders: [],
      selectedRegions: [],
      selectedCountries: [],
      loadingVerticals: false,
      loadingProviders: false,
      rawKeywords: null,
      keywordsHint: 'Enter one keyword per line in lower case and without match type notations [] or ""',
      keywordsPlaceholder: 'brand name' + '\n' + 'brand name 2' + '\n' + 'brand name 3',
      providersEnabled: true,
      validating: false,
      saving: false,
      showValid: false,
      showSaved: false,
      showErrorAlert: false,
      showWarningAlert: false,
      showSuccessAlert: false,
      errorMsg: '',
      warningMsg: '',
      successMsg: '',
      refreshVerticalsEndpoint: '/a/core/verticals/refresh',
      refreshProvidersEndpoint: '/a/core/providers/refresh',
      validateEndpoint: '/a/nkw/keywords/validate',
      saveEndpoint: '/a/nkw/keywords/add',
      lsSelection: 'addNKWs_selection',
    }
  },

  mixins: [formsMixin],

  computed: {
    activeCountries () {
      return this.$store.state.common.activeCountries.filter(val => val.id !== 0)
    },
    activeProviders () {
      return this.$store.state.core.activeProviders
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals.filter(val => val.id !== 0)
    },
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
    keywordTypes () {
      return this.$store.state.nkw.keywordTypes
    },
    matchTypes () {
      return this.$store.state.core.matchTypes.filter(val => val !== 'Broad')
    },
    regions () {
      return this.$store.state.common.regions.filter(val => val.id !== 0)
    },
  },

  components: {
  },

  methods: {
    getSelectionBody () {

      // make line-separated kws into an array
      var keywords = this.rawKeywords.split('\n')

      // for each keyword
      keywords.forEach(function(val, i, ar) {

        // remove trailing commas
        if (val.substr(val.length-1, 1) === ',') {
          ar[i] = val.substring(0, val.lastIndexOf(','))
        }

        // trim
        ar[i] = ar[i].trim()

        // make lower case
        ar[i] = ar[i].toLowerCase()
      })

      // remove blank lines
      keywords = keywords.filter(v => v !== '')

      // recreate rawKeywords to update UI
      this.rawKeywords = keywords.join('\n')

      // for each keyword
      keywords.forEach(function(val, i, ar) {

        // replace phrase notation " with |, to prevent JSON encoding error when keyword ends with "
        ar[i] = ar[i].replaceAll("\"", "|")

      })

      var selectionObj = {
        'keywordType': this.selectedKeywordType,
        'matchType': this.selectedMatchType,
        'verticalIDs': this.selectedVerticals,
        'providerIDs': this.selectedProviders,
        'regionIDs': this.selectedRegions,
        'countryIDs': this.selectedCountries,
        'keywords': keywords,
      }
      var body = JSON.stringify(selectionObj)
      localStorage.setItem(this.lsSelection, body)
      return body
    },

    keywordTypeChanged () {
      switch (this.selectedKeywordType) {
        case 'Brand':
          this.selectedProviders = []
          this.providersEnabled = true
          break
        case 'Competitor':
          this.selectedProviders = [0]
          this.providersEnabled = false
          break
        case 'Performance':
          this.selectedProviders = [0]
          this.providersEnabled = false
          break
      }
    },

    refreshProviders () {
      this.loadingProviders = true
      this.selectedProviders = []

      this.$http.post(this.refreshProvidersEndpoint).then(resp => {
          this.$store.dispatch('core/loadActiveProviders')
          .catch(errResp => {
            this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadActiveProviders', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
          })
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshProvidersEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingProviders = false)
    },

    refreshVerticals () {
      this.loadingVerticals = true
      this.selectedVerticals = []

      this.$http.post(this.refreshVerticalsEndpoint).then(resp => {
          this.$store.dispatch('core/loadVerticals')
          .catch(errResp => {
            this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadVerticals', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
          })
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshVerticalsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingVerticals = false)
    },

    reset () {
      this.$refs.form.resetValidation()

      this.selectedKeywordType = 'Brand'
      this.selectedMatchType = 'Exact'
      this.selectedVerticals = []
      this.selectedProviders = []
      this.selectedRegions = []
      this.selectedCountries = []
      this.rawKeywords = null

      localStorage.removeItem(this.lsSelection)
    },

    validate (withSave = false) {
      this.showErrorAlert = false
      this.showWarningAlert = false
      this.showSuccessAlert = false
      if (!this.$refs.form.validate()) {
        return false
      }

      if (this.selectedRegions.length == 0 && this.selectedCountries.length == 0) {
        this.errorMsg = 'At least 1 region or country must be chosen'
        this.showErrorAlert = true
        return false
      }

      var action = 'validating'
      var showVar = 'showValid'
      var endpoint = this.validateEndpoint
      if (withSave) {
        action = 'saving'
        showVar = 'showSaved'
        endpoint = this.saveEndpoint
      }

      this[action] = true

      var body = this.getSelectionBody()

      this.$http.post(endpoint, body).then(resp => {
        if (resp.data.data.warning_msgs) {
          this.warningMsg = this.forms_getValidationDisplay(resp.data.data.warning_msgs, 'warning(s)')
          this.showWarningAlert = true
        }
        if (resp.data.data.error_msgs) {
          this.errorMsg = this.forms_getValidationDisplay(resp.data.data.error_msgs, 'error(s)')
          this.showErrorAlert = true
        } else {
          if (resp.data.data.success_msgs) {
            this.successMsg = resp.data.data.success_msgs.join('\n')
            this.showSuccessAlert = true
          }
          this[showVar] = true
          this.timer = setTimeout(() => { this[showVar] = false }, 3000)
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: endpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => {
        this[action] = false
      })
    }
  },

  created: function () {
    // if present, use local selection
    if (localStorage.getItem(this.lsSelection)) {
      let selection = JSON.parse(localStorage.getItem(this.lsSelection))
      this.selectedKeywordType = selection['keywordType']
      this.selectedMatchType = selection['matchType']
      this.selectedVerticals = selection['verticalIDs']
      this.selectedProviders = selection['providerIDs']
      this.selectedRegions = selection['regionIDs']
      this.selectedCountries = selection['countryIDs']
      this.rawKeywords = selection['keywords'].join('\n')
    }
  }
}
</script>

<style scoped>
</style>