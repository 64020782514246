
export default {

  data () {
    return {
      forms_apiError: {
        method: '',
        url: '',
        description: '',
      },

      forms_boolFilterVals: ['Yes', 'No'],
      forms_maxValidationMsgDisplay: 3,
    }
  },

  methods: {
    forms_addItemFieldsToURI: function (uri = '', item = {}, addedFields = []) {
      // add fields from the form item object
      Object.keys(item).forEach(function (key, index) {
        uri += key + ','
      })

      // add fields from addedFields array
      for (var j = 0; j < addedFields.length; j++) {
        uri += addedFields[j] + ','
      }
      
      // trim final comma and return
      return uri.substring(0, uri.length - 1)
    },

    forms_consoleLog (val) {
      // only log to console outside production - TODO log to file instead
      if (process.env.NODE_ENV !== 'production') {
        console.log(val)
      }
    },

    forms_getValidationDisplay (msgArray = [], msgTypePlural = '') {
      var displayArray = msgArray.slice(0, this.forms_maxValidationMsgDisplay)
      var undisplayedErrCount = msgArray.length - this.forms_maxValidationMsgDisplay

      if (undisplayedErrCount > 0) {
        displayArray.push('+ ' + undisplayedErrCount + ' further ' + msgTypePlural)
      }

      return displayArray.join('\n')
    },

    forms_setAllObjectAttribs (obj, val) {
      Object.keys(obj).forEach(function (index) {
        obj[index] = val
      })
    },

    forms_resetAllObjectAttribs (obj) {
      this.forms_setAllObjectAttribs(obj, null)
    },
  },
}
